@font-face {
  font-family: 'Futura';
  src: url('../public/futura/FuturaCyrillicBook.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}


body {
  font-family: 'Futura', sans-serif !important;
  font-size: 18px !important;
}
body {
  font-family: 'Futura', sans-serif;
}
.carousel-indicators {
  display: none !important;
}
.header-logo{
  width: 12%;
  padding: 10px;
  margin: 2px;
}
.anchor-footer{
  color: white;
  font-size: 15px;
  text-decoration: none;

}
.logo-row{
  display: flex;
  flex-wrap: nowrap;
}
.input-search{
  border: 1px solid #dad4d4;
  padding: 5px;
  margin: 27px;
  width: 400px;
}
.banner{
  width: 100%;
}
.menu-list{
  padding: 0px;
  margin: 10px;
  font-weight: 700;
  color: white;
  display: flex;
  
  white-space: nowrap;
  gap: 50px;
  list-style: none;
}
.menubar-row{
  background: #a07257;
  color: white;
  justify-content: center;
}
.product-image{
  width: 100%;
  background: gainsboro;
}
.category-title{
  /* border-bottom: 1px solid #000; */
  line-height: 0.1em;
  margin-top: 30px;
  margin-bottom: 22px;
  text-align: center;
  font-size: 24px;;
}
.category-title2 {
  background: white;
  padding: 17px;
  font-weight: 500;
  font-size: 22px;
}
.desc-container{
  background:#fdfff0;
  text-align: center;
}
.desc2-container{
  text-align: center;
}
.desc2-desc2 {
  padding: 15px 10px 10px 10px;
  line-height: 25px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 26px;
}
.desc2-desc3{
  margin-bottom: 39px;
}
.desc-desc{
  padding: 32px 10px 10px 10px;
  line-height: 25px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 26px;
}
.flavour-tile{
  font-size: 22px;
}
.flavour-images{
  width: 100%;
}
.flavour-product{
  padding: 8px;
  background: white;
  text-align: left;
  color: black;
  text-decoration: none !important;

}
.product-div{
  padding: 10px;
 
}
.product-name{
  color: black;
  text-align: center;
  margin-top: 14px;
}
.product-row{
  justify-self: center;
  margin-top: 32px;
}
.design-image{
  cursor: pointer;
  width: 100%;
  background: gainsboro;
}
.design-name{
  cursor: pointer;
  font-weight: 500;
  color: black;
  margin-bottom: 5px;
  /* text-transform: uppercase; */
  margin-top: 14px;
}
.design-name::first-letter {
  text-transform: uppercase; /* Capitalizes the first letter */
}
.design-row{
  margin-top: 20px;
}
.form-group{
  margin-top: 15px;
  margin-bottom: 10px;
}


.design-menu{
  list-style: none;
  padding: 0;
  display: flex;
  gap: 0px;
  margin-top: 10px;
}
.menubar-row2{
  
    border-radius: 40px;
    padding: 24px;
    height: 100px;
    color: black;
    justify-content: center;
}
.menu-list2 {
  padding: 0px;
  /* justify-content: center; */
  /* margin: 10px; */
  margin-top: 50px;
  /* margin-top: 21px; */
  font-weight: 700;
  display: flex;
  gap: 100px;
  list-style: none;
}
.header2-div{
  background-color: #f7a924;
}
.header2-div2{
  
  display: flex;
  position: absolute;
  width: 90%;
  top: 7%;
  box-shadow: 0 0 10px gray;
  border-radius: 40px;
  background-color: white;
}
.design-container{
  margin-top: 0px;
}
.sidenav{
 
    width: 250px;
    right: 0px;
    left: auto;

}
.symbol-logo{
  width: 120px !important;
  margin: 12px;
  position: absolute;
    padding: 1px;
  
}
.anchor{
  font-size: 17px !important;
  color: black !important;
}
.header-div{
  background: #1f4b59;
}
.design-ds1{
  font-size: 14px;
  /* margin-bottom: 50px; */
}
.div-row{
  display: flex;
  width: 100%;
  gap: 14%
    /* text-align: center; */
}

.design-div-main{
  /* max-width: 25.333333% !important;
  margin: 0px 57px 41px 33px; */
  padding: 10px;
}

/* .design-div::before, 
.design-div::after {
  content: '';
  position: absolute;
  width: 70px; 
  height: 70px;
} */

/* .design-div::before {
  top: 16px;
    left: 29px;
  border-top: 3px solid #f5a941;
  border-left: 3px solid #f5a941;
} */

/* .design-div::after {
  margin-top: -110px;
  margin-left: 20%;
  border-bottom: 3px solid #f5a941;
  border-right: 3px solid #f5a941;
} */



.footer ul{
  line-height: 30px;
  list-style-type: none;
  padding: 0px;
}

.footer-main{
  position: relative;
  bottom: 0;
  width: 100%;
  margin-top: 20px;
  background: #1f4b59;
  color: white;
  font-weight: 400;
  font-size: 15px; 
}
.footer{
  text-align: center;
}
.footer p {
  margin-top: 12px;
  font-size: 16px;
  color: white;
  font-weight: 500;
}

.review-img img{
  width: 15px;
}

.review-row{
  background-color: rgb(64 72 83);
  color: white;
  padding: 10px;
}

.review-row p{
  margin-bottom: 0px;
  font-weight: 400;
}

.terms-row{
  display: flex;
  gap: 30px;
  padding-left: 175px;
}

.preview-design-name{
  font-size: 20px;
  /* margin-top: 10px; */
  /* font-weight: 600; */
}
.form-control-group{
  font-size: 15px !important;
    margin-top: 10px !important;
    margin-bottom: 12px !important;
}
.preview-ds1{
  font-size: 14px;
  margin-top: 14px;
}
.preview-container{
  margin-top: 50px;
}
.quote-div{
  padding: 0px 10px 10px 10px;
}

.quote-title{
  font-size: 16px;
  margin-top: 10px;
  font-weight: bold;
}
.title-get-quote{
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-decoration: underline;
 
  margin-top: 20px;
  margin-bottom: 27px;
}
.submenu{
  color: black !important;
}
.service-row-icon{
  justify-content: center;
  gap: 8%;
  padding: 26px;

}
.icon-div{
text-align: center;
}
.icon-service{
  width: 26%;
}
.icon-title{
  font-size: 16px;
  margin-top: 15px;
}
h2 {
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid #000; 
  line-height: 0.1em;
  margin: 10px 0 20px; 
} 

h2 span { 
   background:#fff; 
   padding:0 10px; 
}
.footer-row{
  margin-left: -100px !important;
  margin-right: -100px !important;
}
.product-row{
  margin-left: -15px !important;
  margin-right: -15px !important;
}


.enquire-button{
  background: #1f4c59 !important;
  color: white !important;
}
.button-menu {
  position: absolute;
  font-size: 30px;
  cursor: pointer;
  right: 12px;
  padding: 25px;
  color: white;
}
.product-container{
  margin-top: 20px;
}
.title-about {
  padding: 10px;
  line-height: 36px;
  margin-bottom: 4px;
  text-align: center;
}
.hr-tag{
  border: 2px solid #554b4a;
    margin-bottom: -11px;
}
body {
  font-family: "Lato", sans-serif;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0; /* Changed from left: 0 to right: 0 for RTL */
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}
.symbol-logo2{
  padding: 12px;
  width: 126px;
}
.sidenav a {
  padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 19px;
    color: black;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px; /* Changed from right to left */
  font-size: 36px;
  margin-left: 50px;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  top: 0;
  z-index: 999999 !important;
  right: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}
.submenu-li{
  font-size: 15px !important;
  color: black !important;
}
.list-unstyled {
  line-height: 20px !important;
  padding-left: 0;
  color: black !important;
  list-style: none;
}
.no-underline{
 text-decoration: none !important;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 17px;
  color: #1f4b59;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  border: none;
  background: none;
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.submenu {
  text-decoration: none;
  list-style-type: none;
  padding-left: 20px;
}

.submenu li {
  margin-top: 10px;
}

.button-menu, .submenu-btn {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.button-menu {
  font-size: 30px;
}
.menu-btn{
  background: none;
  border: none;
  margin-left: 25px;
  margin-bottom: 3px;
  margin-top: 3px;
  cursor: pointer;
  text-decoration: none;
    font-size: 18px;
    
    color: black;
}
.search{
  width: 21%;
  position: absolute;
  right: 9%;
  top: 37px;
}
.custom-dropdown {
  list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    background-color: gainsboro;
    position: absolute;
    right: 9%;
    font-size: 13px;
    top: 15%;
    width: 300px;
    z-index: 1;
}

.custom-dropdown li {
  padding: 8px;
  cursor: pointer;
}

.custom-dropdown li:hover {
  background-color: #f0f0f0;
}
.carousel-control-prev {
  left: -66px !important;
}
.carousel-control-next {
  right: -54px !important;
}
.flavour-product-row{
  padding-bottom: 23px;
}
.about-container{
  text-align: center;
}
.label-get-qoute{
  margin-left: -11px;
    margin-bottom: 4px;
}
.about-text{
  padding: 27px 10px 28px 10px;
  line-height: 35px;
}
.shadow-div {
  transition: box-shadow 0.8s ease, transform 0.4s ease; /* Duration and easing of the transition */
}

.shadow-div:hover {
  box-shadow: 0 29px 52px rgba(0, 0, 0, 0.2), 0 25px 16px rgba(0, 0, 0, 0.2);
  
}
.about-us-p {
  margin-top: -28px !important;
  margin-bottom: 25px !important;
}
.contact-us-title{
  font-size: 22px;
  
}
.contact-us-container{
  margin-top: 40px;
  margin-bottom: 82px;
  font-size: 14px;
}
.contact-us-row{
  justify-content: center;
}
.first-div-card-about{
 
  display: flex;
  flex-wrap: nowrap;
}
.second-div-card-about{
  display: flex;
  flex-wrap: nowrap;
}
.third-div-card-about{
  display: flex;
  flex-wrap: nowrap;
}
.address-contact-us{
  padding-left: 60px;
  font-size: 13px;
}
.title-span{
  font-size: 15px;
  font-weight: 600;
}
.contact-contact-us{
  line-height: 22px;
  padding-left: 60px;
  font-size: 13px;
}
.card-header-title{
  font-size: 18px;
    margin-bottom: 6px;
}
.card-label-about{
  margin-left: -15px;
  margin-bottom: 7px;
}
.card-group-about{
  padding: 0px 10px 2px 10px;
}
.contact-us-btn{
  background: #fdc20f !important;
  border: none;
  padding: 5px;
}
.vibrate {
  animation: vibrate 0.3s;
}

@keyframes vibrate {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}
/* Add this CSS to handle the red border and vibration effect */
.input-error .form-control {
  border: 1px solid red;
  animation: vibrate 0.3s ease-in-out;
}

/* Vibration effect keyframes */
@keyframes vibrate {
  0% { transform: translateX(0); }
  20% { transform: translateX(-2px); }
  40% { transform: translateX(2px); }
  60% { transform: translateX(-2px); }
  80% { transform: translateX(2px); }
  100% { transform: translateX(0); }
}





