@media only screen and (min-width: 1200px) {
    .symbol-logo {
        width: 70px;
        margin: 12px;
        margin-left: 40px !important;
    }
    .custom-dropdown {
      list-style-type: none;
      padding: 0;
      margin: 0;
      border: 1px solid #ccc;
      max-height: 200px;
      overflow-y: auto;
      background-color: gainsboro;
      position: absolute;
      right: 9%;
      font-size: 13px;
      top: 7%;
      /* width: 399px; */
      z-index: 1;
  }
  .button-menu {
    position: absolute;
    font-size: 30px;
    cursor: pointer;
    right: 12px;
    padding: 30px;
    color: white;
}
.menu-btn{
margin-left: 20px;
}
    .design-name{
      cursor: pointer;
      font-weight: 500 !important;
      color: black;
      margin-bottom: 5px;
      /* text-transform: uppercase; */
      text-decoration: none;
      margin-top: 14px;
    }
    .menu-list2 {
        padding: 0px;
        /* justify-content: center; */
        /* margin: 10px; */
        margin-top: 55px;
        /* margin-top: 21px; */
        font-weight: 700;
        display: flex;
        gap: 200px !important;
        list-style: none;
    }
    .footer-row{
        /* margin-left: -130px !important;
        margin-right: -130px !important; */
      }
      .product-row{
        /* margin-left: -130px !important; */
        /* margin-right: -130px !important; */
      }
      .design-menu {
        list-style: none;
        padding: 0;
        font-size: 15px;
        display: flex;
        gap: 0px;
        margin-top: 20px;
    }
    .space-content{
      margin: 26%;
    }
    .space-content2{
      margin: 43%;
    }

}