.siderbar{
    text-align: center;
    position: fixed;
    z-index: 9;
    width: 200px;
    line-height: 40px;
    background: #d4d4d4;
    height: 100%;
}
.menu{
    list-style: none;
    line-height: 54px;
    margin: 0; /* Removes margin from each list item */
    padding: 0;
    text-align: left;
    text-decoration: none;
}
.admin-footer{
    position: fixed;
    width: 100%;
    background: antiquewhite;
    bottom: 0%;
}
.navbar{
    justify-content: center;
    background: aliceblue;
    position: relative;
    top: 0%;
    width: 100%;
}
.content{
    margin-left: 18%;
    padding: 20px;
}
.sider-menu{
    color: black;
}

.menu li {
    margin-left: 38px;
    cursor: pointer; /* Change cursor to indicate clickable items */
}
td{
    border: 1px solid rgb(204, 204, 204)!important;
    padding: 10px;
    text-align: center;
    font-size: 13px;
}
th{
    border: 1px solid rgb(204, 204, 204) !important;
    padding: 10px;
    text-align: center !important; 
    font-size: 16px;
}
.orderPage-title{
    font-size: 20px;
    text-align: center;
}
.product-title{
    font-size: 20px;
    text-align: center;
}
.design-title{
    font-size: 20px;
    text-align: center;
}
.sub-menu {
    list-style: none; /* Removes bullet points from sub-menu */
    padding: 0;
    margin: 0;
}
.admin-logo{
    width: 70px;
    margin: 12px;
}

.active {
    font-weight: bold; /* Make active menu bold */
    color: #007bff; /* Change color to indicate active state */
}
.logout-btn{
    padding: 5px;
    font-size: 13px;
    float: right;
    border: none !important;
    background: #fdc20f !important;
}
.navbar-container{
    background: #f0f8ff;
    display: flex;
    justify-content: right;
    flex-wrap: nowrap;
    padding: 20px;
}
.login-container{
    justify-self: center;
    text-align: center;
    padding: auto;
    margin:auto
}
.password-id-div{
    text-align: left;
    font-size: 13px;
    padding: 20px;
}
.login-id-div{
    text-align: left;
    padding: 20px;
    font-size: 13px;
}
.login-card{
    
    margin-top: 15% !important;
    width: 30% !important;
}
.login-row{
    justify-content: center;
}
.admin-title{
    text-decoration: underline;
    margin-bottom: 3px;
    font-size: 18px;
    padding: 10px 10px 0px 10px;
}
.admin-label{
    padding-bottom: 10px;
}


