@media only screen and (max-width: 600px) {
    body {
        font-family: 'Futura', sans-serif !important;
        font-size: 18px !important;
      }
.design-sm{
    width: 50% !important;
}
.design-name{
    font-size: 11px !important;
}
    .service-row-icon{
        display: flex;
        flex-wrap: nowrap !important;
    }
    .icon-title {
        font-size: 11px;
        margin-top: 15px;
    }
    .symbol-logo2 {
        padding: 12px;
        width: 74px;
    }
    .sidenav{
        width: 169px;
        right: 0px;
        left: auto;
    
    }
    .sidenav a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 14px;
        color: black;
        display: block;
        transition: 0.3s;
    }
    
      .anchor-footer {
    color: white;
    font-size: 11px;
}
    .footer ul{
        line-height: 25px;
        list-style-type: none;
        padding: 0px;
        font-size: 11px;
      }
      .footer p {
        margin-top: 12px;
        font-size: 14px;
        color: white;
        font-weight: 500;
    }
    .menu-list2 {
        white-space: nowrap !important;
        font-size: 11px;
        padding: 0px;
        /* margin: 10px; */
        margin-top: 21px;
        font-weight: 700;
        display: flex;
        gap: 24px;
        list-style: none;
    }
    .symbol-logo {
        width: 78px !important;
        padding: 8px 16px 1px 13px !important;
        margin: auto;
    }
    .desc-desc2{
    padding: 32px 10px 10px 10px;
    line-height: 25px;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 26px;
    }
    .design-image {
        cursor: pointer;
        width: 100%;
        background: gainsboro;
    }
    .preview-container {
        margin-top: 0px;
    }
    .button-menu {
        position: absolute;
        font-size: 26px;
        cursor: pointer;
        right: 12px;
        padding: 8px;
        color: white;
    }
    .title-about {
        font-size: 14px !important;
        padding: 10px;
        line-height: 27px;
        margin-bottom: -3px;
        text-align: center;
    }
    .category-title2 {
        background: white;
        padding: 17px;
        font-weight: 500;
        font-size: 16px !important;
    }
  
    .menubar-row2 {
        text-align: center;
        border-radius: 40px;
        padding: 24px;
        height: 58px !important;
        color: black;
        justify-content: center;
  }
  .header2-div2 {
    display: flex;
    flex-wrap: nowrap !important;
    position: absolute;
    width: 90%;
    top: 1% !important;
    justify-self: center;
    box-shadow: 0 0 10px gray;
    border-radius: 40px;
    background-color: white;
}
.category-title2 {
    background: white;
    padding: 6px !important;
}
.category-title {
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin-top: -3px !important;
   
    text-align: center;
    font-size: 15px !important;
}
.product-row {
    justify-self: center;
    margin-top: 20px !important;
}
.div-row {
    display: flex !important;
    width: 100% !important;
    gap: 6px !important;
}
.design-menu {
    font-size: 11px !important
}
.anchor {
    color: #000 !important;
    font-size: 11px !important;
}
.footer-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.design-div::before {
    top: 19px;
    left: 36px;
    border-top: 1px solid #f5a941;
    border-left: 1px solid #f5a941;
}
.design-div::after {
    bottom: 33px;
    right: 5px;
    border-bottom: 1px solid #f5a941;
    border-right: 1px solid #f5a941;
}

.design-div::before, .design-div::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
}
.design-div-main{
    /* max-width: 25.333333% !important;
    margin: 0px 57px 41px 33px; */
    padding: 0px;
  }
.design-row {
    /* margin-right: -7px !important;
    margin-left: -39px !important; */
    margin-top: 10px;
}
.footer {
    text-align: center;
    font-size: 12px;
}
.product-name {
    color: black;
    text-align: center;
    margin-top: 14px;
    font-size: 17px;
}
.desc-desc {
    padding: 32px 10px 10px 10px;
    line-height: 25px;
    text-align: center;
    font-size: 13px;
    margin-top: 24px;
    margin-bottom: 26px;
}
.desc2-desc2 {
    padding: 0px 10px 10px 10px;
    line-height: 25px;
    text-align: center;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 26px;
}
.desc2-desc3 {
    font-size: 13px;
}
.flavour-tile {
    font-size: 16px;
}
.flavour-product {
    font-size: 16px;
    padding: 8px;
    text-align: center;
    background: white;
}
.search {
    width: 48%;
    position: absolute;
    right: 21%;
    top: 19px;
    font-size: 14px;
}
.custom-dropdown {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    background-color: gainsboro;
    position: absolute;
    right: 78px;
    font-size: 9px;
    top: 45px;
    width: 173px;
    z-index: 1;
}
}